.container {
  background: transparent
    radial-gradient(
      closest-side at 50% 100%,
      rgba(255, 0, 221, 0.34) 0%,
      rgba(255, 0, 221, 0) 100%
    )
    0% 0% no-repeat padding-box;
  height: 100%;
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.0625rem;
}

.bodyContainer {
  max-width: 1440px;
  padding: 0 2.5rem;
  margin: 0 auto;
}

.hero {
  font-size: 1.625rem;
  color: var(--text-color);
  margin-bottom: 1.5625rem;
}

.header {
  font-size: 1.625rem;
  color: var(--text-color);
}

.subHeader {
  font-size: 1rem;
  color: var(--primary-color);
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.6875rem 0;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.left {
  align-items: start;
}
.imgBg {
  height: 7.1875rem;
  width: 7.1875rem;
  border-radius: 3.625rem;
  background-color: #c67ba6;
}

.nameCol {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  margin-left: 1.5rem;
}

.fn {
  color: var(--primary-color);
  font-size: 2.125rem;
  margin-right: 1rem;
}

.sn {
  color: var(--text-color);
  font-size: 1.5625rem;
  line-height: 1;
  border: 1px solid var(--primary-color);
  border-radius: 0.625rem;
  padding: 0.625rem 0.875rem;
}

.seeContract {
  color: var(--green-header);
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.7688rem;
}

.purpleCircle {
  cursor: pointer;
  position: relative;
  height: 2.8125rem;
  width: 2.8125rem;
  border-radius: 1.40625rem;
  left: calc(100% - 2.125rem);
  top: calc(100% - 2.8125rem);
}

.purpleCircle img {
  cursor: pointer;
  height: 2.8125rem;
  width: 2.8125rem;
}

.scoreRow {
  height: 8.75rem;
}

.scoreCircle {
  position: absolute;
  height: 7.1875rem;
  width: 7.1875rem;
  border-radius: 3.625rem;
  /* background-color: #c67ba6; */
  z-index: 1;
}

.scoreCircle img {
  position: absolute;
  height: 7.1875rem;
  width: 7.1875rem;
  z-index: -1;
  object-fit: cover;
  border-radius: 3.625rem;
  background-color: #49344a;
}

.right {
  align-items: end;
}

.scoreText {
  padding: 1.6875rem 3.5313rem;
  margin-right: 5.875rem;
  border-top-left-radius: 4.0625rem;
  border-bottom-left-radius: 4.0625rem;
  border: 1px solid var(--text-color);
  border-right-width: 0;
}

.scoreNumber {
  font-size: 2.5625rem;
}

.outOf {
  font-size: 0.875rem;
  color: var(--primary-color);
}

.section {
  padding: 3.875rem 0;
}

.subSection {
  margin-top: 2.0625rem;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent linear-gradient(270deg, #e1007f00 0%, #e1007f26 100%)
    0% 0% no-repeat padding-box;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5156rem;
}

.normalText {
  font-size: 1.25rem;
  color: var(--text-color);
}

.charts {
  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: center;
}

.charts a {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
  margin-left: 1rem;
}

.scoreDesc,
.descText {
  margin-top: 2.5625rem;
  opacity: 0.75;
  color: var(--text-color);
  font-size: 0.9375rem;
}

.bulletRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.4375rem 0;
}

.pinkTick {
  padding: 0 4rem;
  height: 2.0719rem;
  object-fit: contain;
}

.minusBullet {
  padding: 0 4rem;
  height: 3.3262rem;
  object-fit: contain;
}

.descText {
  margin-top: 1.875rem;
}

.note {
  margin-top: 1.4375rem;
  font-size: 0.9375rem;
  letter-spacing: 0px;
  color: #ee5018;
  opacity: 0.75;
}

@media (max-width: 768px) {
  .bodyContainer {
    padding: 1.5rem 1rem;
  }

  .header {
    font-size: 1.375rem;
  }

  .subHeader {
    font-size: 0.875rem;
  }

  .section {
    padding: 1.875rem 0;
  }

  .normalText {
    font-size: 1rem;
  }

  .infoContainer {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }

  .left,
  .right {
    width: 100%;
    align-items: flex-start;
  }

  .nameCol {
    margin-left: 0;
  }

  .imgBg {
    margin-right: 1rem;
  }

  .fn {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .sn {
    font-size: 1.125rem;
    padding: 0.5rem 0.75rem;
  }

  .seeContract {
    font-size: 0.75rem;
    margin-left: 0.6rem;
  }

  .purpleCircle {
    left: calc(100% - 2.5rem);
    top: calc(100% - 3.125rem);
    display: none;
  }

  .scoreText {
    padding: 1rem 2.5rem;
    margin-right: 3.5rem;
  }

  .scoreNumber {
    font-size: 2rem;
  }

  .outOf {
    font-size: 0.75rem;
  }

  .scoreDesc,
  .descText {
    font-size: 0.75rem;
    margin-top: 1.5rem;
  }

  .bulletRow {
    padding: 1rem 0;
  }

  .detailsRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .detailsRow div:last-child {
    font-size: 0.875rem;
    margin-top: 0.425rem;
  }

  .charts {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .charts a {
    margin-left: 0;
    margin-right: 0.875rem;
  }

  .pinkTick {
    padding: 0 1.675rem;
    height: 1.5rem;
  }

  .minusBullet {
    padding: 0 1.675rem;
    height: 2.5rem;
  }

  .note {
    margin-top: 1rem;
    font-size: 0.75rem;
    font-size: 1rem;
  }

  .sn {
    font-size: 0.875rem;
  }

  .imgBg {
    height: 5.1875rem;
    width: 5.1875rem;
  }

  .seeContract {
    font-size: 0.75rem;
  }
}
