.container {
  background: transparent
    radial-gradient(
      closest-side at 50% 100%,
      rgba(255, 0, 221, 0.34) 0%,
      rgba(255, 0, 221, 0) 100%
    )
    0% 0% no-repeat padding-box;
  height: 100%;
}

.bodyContainer {
  padding: 0rem 12.9375rem;
}

.header {
  font-size: 1.625rem;
  color: var(--text-color);
}

.gridContainer {
  margin-top: 3.75rem;
  width: 100%;
  height: 100%;
}

.headersGrid,
.transactionsGrid {
  display: grid;
  grid-template-columns: 0.5fr 10fr 6fr 2.5fr;
  grid-gap: 2.25rem;
  width: 100%;
}

.headersGrid {
  font-size: 0.625rem;
  color: var(--footer-text);
  opacity: 0.75;
  margin-bottom: 0.9531rem;
  padding: 0 1rem;
}

.transactionsGrid {
  font-size: 1rem;
  padding: 1.375rem 1rem;
  margin-bottom: 0.375rem;
  background: transparent linear-gradient(270deg, #e1007f00 0%, #e1007f26 100%)
    0% 0% no-repeat padding-box;
}

.addressText {
  text-decoration: underline;
}

.amountText {
  color: #ac9ba5;
}

.profitText {
  color: var(--green-header);
}

.lossText {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .bodyContainer {
    padding: 3.75rem 1rem;
  }

  .headersGrid {
    font-size: 0.625rem;
    margin-bottom: 0.75rem;
  }

  .transactionsGrid {
    font-size: 0.625rem;
    padding: 1rem;
    margin-bottom: 0.375rem;
  }
}
